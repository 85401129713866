if ($('#quote').length) {
	var form = $("#demo");
	var currentIndex;
	var steps;
	var wizardLength = form.find('.step-steps li').length - 1;

	form.steps({
		headerTag: 'h3',
		bodyTag: 'section',
		transitionEffect: 'slideLeft',
		enableFinishButton: false,
		startIndex: 0,
		currentIndex: 1,
		saveState: true
	});

	$('.btn-no-click').on('click', function (ev) {
		ev.preventDefault();
	});

	$('.btn-no-step3').on('click', function (ev) {
		ev.preventDefault();

		$('.step-steps li[data-step-target="step3"]').trigger('click');
	});

	$('.btn-no-step4').on('click', function (ev) {
		ev.preventDefault();

		$('.step-steps li[data-step-target="step4"]').trigger('click');
	});

	$('.btn-no-step5').on('click', function (ev) {
		ev.preventDefault();

		$('.step-steps li[data-step-target="step5"]').trigger('click');
	});

	$('.btn-no-step6').on('click', function (ev) {
		ev.preventDefault();

		$('.step-steps li[data-step-target="step6"]').trigger('click');
	});

	$('.list-cover-types .sub-radio').each(function () {
		$(this).on('click', function () {
			$(this).prop('checked', true);

			$('.step-steps li[data-step-target="step3"]').trigger('click');
		});
	});

	$('.sub-radio.mod-yes').on('click', function () {
		$('.step-steps li[data-step-target="step5"]').trigger('click');
	});

	$('.sub-radio.mod-no').on('click', function () {
		$('.step-steps li[data-step-target="step6"]').trigger('click');
		$('#who-policy, #annual-premium').val('');
	});

	$(window).on('load', function () {
		$('#quoteForm .btn, #quoteForm .sub-radio').on('click', function () {
			if ($('section[data-step="step2').hasClass('active')) {
				$('.sub-progress-bar').css('width', '0%');
				$('.sub-percent').html('0%');
			}
			if ($('section[data-step="step3').hasClass('active')) {
				$('.sub-progress-bar').css('width', '20%');
				$('.sub-percent').html('20%');
			}
			if ($('section[data-step="step4').hasClass('active')) {
				$('.sub-progress-bar').css('width', '40%');
				$('.sub-percent').html('40%');
			}
			if ($('section[data-step="step5').hasClass('active')) {
				$('.sub-progress-bar').css('width', '60%');
				$('.sub-percent').html('60%');
			}
			if ($('section[data-step="step6').hasClass('active')) {
				$('.sub-progress-bar').css('width', '80%');
				$('.sub-percent').html('80%');

			}
			if ($('section[data-step="step7').hasClass('active')) {
				$('.sub-progress-bar').css('width', '100%');
				$('.sub-percent').html('100%');
			}
		});

		$('input[type="checkbox"]').on('change', function () {
			if ($(this).prop('checked')) {
				$(this).val('No');
			} else {
				$(this).val('Yes');
			};
		});

		$('#you').on('change', function () {
			$('.sub-couple, .sub-family').removeClass('mod-active');
			$('.sub-you').addClass('mod-active');
			$('.sub-first-step .btn').addClass('mod-btn-you');
			$('.sub-family .sub-dob input, .sub-couple .sub-dob input').val('');
			$('.sub-nav.mod-couple-nav, .sub-first-step, .sub-second-step').hide();
			$('.sub-nav.mod-you-nav').show();
		});

		$('#quoteForm .sub-you .sub-dob input').on("keyup keydown change click blur", function () {
			var empty = false;
			$('#quoteForm .sub-you .sub-dob input').each(function () {
				if ($(this).val() == '') {
					empty = true;
				}
			});

			if (empty) {
				$('.sub-nav.mod-you-nav .btn-primary').attr('disabled', 'disabled');
			} else {
				$('.sub-nav.mod-you-nav .btn-primary').removeAttr('disabled');
			}
		});

		$('#couple').on('change', function () {
			$('.sub-you, .sub-family').removeClass('mod-active');
			$('.sub-couple').addClass('mod-active');
			$('.sub-family .sub-dob input, .sub-you .sub-dob input').val('');
			$('.sub-nav.mod-you-nav, .sub-first-step,.sub-second-step').hide();
			$('.sub-nav.mod-couple-nav').show();
		});

		$('#quoteForm .sub-couple .sub-person-wrap input').keyup("keyup keydown change click blur", function () {
			var empty = false;
			$('#quoteForm .sub-couple .sub-dob input').each(function () {
				if ($(this).val() == '') {
					empty = true;
				}
			});

			if (empty) {
				$('.sub-nav.mod-couple-nav .btn-primary').attr('disabled', 'disabled');
			} else {
				$('.sub-nav.mod-couple-nav .btn-primary').removeAttr('disabled');
			}
		});

		$('#family').on('change', function () {
			$('.sub-you, .sub-couple').removeClass('mod-active');
			$('.sub-family').addClass('mod-active');
			$('.sub-couple .sub-dob input, .sub-you .sub-dob input').val('');
			$('.sub-first-step').show();
			$('.sub-second-step, .sub-nav.mod-couple-nav, .sub-nav.mod-you-nav').hide();
		});

		$('.btn-family-member').click(function (ev) {
			ev.preventDefault();

			$('.list-members').fadeIn();
			$('.sub-add-members, .sub-first-step, h3.mod-main-heading, .sub-nav.mod-you-nav, .sub-nav.mod-couple-nav, .sub-first-step').hide();
			$('.sub-second-step, h3.mod-family-pref').show();
		});

		$('#quoteForm .mod-step6-wrap .sub-input input').keyup("keyup keydown change click blur", function () {
			var empty = false;
			$('#quoteForm .mod-step6-wrap .sub-input input').each(function () {
				if ($(this).val() == '') {
					empty = true;
				}
			});

			if (empty) {
				$('.btn-already-cover').attr('disabled', 'disabled');
			} else {
				$('.btn-already-cover').removeAttr('disabled');
			}
		});
	});

	$('.btn-start').click(function () {
		$('.sub-progress').css('display', 'flex');
		$('form#quoteForm').addClass('mod-style');
	});

	$('.btn-reset-family').click(function (ev) {
		ev.preventDefault();

		$('.step-steps li[data-step-target="step2"]').trigger('click');
		$('.sub-add-members, .sub-first-step, h3.mod-main-heading').show();
		$('.list-members, .sub-second-step, h3.mod-family-pref').hide();
	});


	$('#adds').click(function add() {
		var $rooms = $("#amount-family-members");
		var a = $rooms.val();

		a++;
		$("#subs").prop("disabled", !a);
		$rooms.val(a);

		$('#amount-family-members').trigger('change');
	});
	$("#subs").prop("disabled", !$("#amount-family-members").val());

	$('#subs').click(function subst() {
		var $rooms = $("#amount-family-members");
		var b = $rooms.val();
		if (b >= 2) {
			b--;
			$rooms.val(b);
		}
		else {
			$("#subs").prop("disabled", true);
		}

		$('#amount-family-members').trigger('change');
	});

	$("#amount-family-members").change(function () {
		if ($(this).val() > '8') {
			$(this).val('8');
			$("#adds").prop("disabled", true);
		} else if ($(this).val() < '1') {
			$('section[data-step="step3"] .btn-family-member').prop("disabled", true);
		} else {
			$("#adds").prop("disabled", false);
			$('section[data-step="step3"] .btn-family-member').prop("disabled", false);

		}
	});


	$(window).keydown(function (event) {
		if (event.keyCode == 13) {
			event.preventDefault();
			return false;
		}
	});

	$('#amount-family-members').change(function () {
		$('#right-column div.sub-person-wrap').remove();
		for (var i = 1; i <= this.value; i++) {
			$('#right-column').append(
				'<div class="sub-person-wrap"><div class="sub-dob"><label>Person ' + i + '</label><input id="family-date' + i + '" name="family-date' + i + '" type="text" placeholder="DD" class="form-input" autocomplete="off"><input id="family-month' + i + '" name="family-month' + i + '" type="text" placeholder="MM" class="form-input" autocomplete="off"><input id="family-year' + i + '" name="family-year' + i + '" type="text" placeholder="YYYY" class="form-input" autocomplete="off"></div><div class="sub-smoker"><span>Are you a Smoker?</span><label class="switch"><input type="checkbox" name="family-smoker' + i + '" id="togBtn" value="No" checked=""><div class="slider round"><span class="no">No</span><span class="yes">Yes</span></div></label></div></div>'
			);
		}
	});
}

// Ajax referral form processing
$('#quote form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}


	$('#quote form .form-input').each(function () {
		if ($(this).val() === '') {
			$(this).parent().addClass('mod-error');
		} else {
			$(this).parent().removeClass('mod-error');
		}
	});

	$.ajax({
		method: 'POST',
		url: 'process/quote/',
		data: $(this).serialize()
	}).done(function (response) {
		// If no errors
		if (response == 'success') {
			window.location.href = "https://www.healthyhorizons.org.uk/getquote/thank-you";

			$('#quote form .form-input').each(function () {
				$(this).val('');
			});
		}
		else {
			$('#quoteForm .sub-errors').fadeIn().html(response);
		}
	});
});